<template>
  <div>
    <b-row>
      <b-col
        md="12"
        class="mb-1"
        style="display: flex; gap:10px;"
        v-if="width<768"
      >
        <b-button v-b-modal.modal-hierachy>
          <feather-icon icon="GitPullRequestIcon" />
        </b-button>
        
      </b-col>
    </b-row>
    <b-row>
      <b-col md="9">
        <b-card no-body class="p-1">
          <b-card-title class="mb-2">{{ $t("tabs.reportDaily") }}</b-card-title>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Date from"
                label-for="datefrom"
                rules="required"
              >
                <template #label>{{ $t("labels.datefrom") }}</template>
                <flat-pickr
                  v-model="dateFrom"
                  @input="changeReport"
                  class="form-control"
                  :config="{
                    altInput: true,
                    altFormat: 'd-m-Y - H:i',
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i',
                    time_24hr: true,
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Date To" label-for="dateto" rules="required">
                <template #label>{{ $t("labels.dateto") }}</template>
                <flat-pickr
                  v-model="dateTo"
                  @input="changeReport"
                  class="form-control"
                  :config="{
                    altInput: true,
                    altFormat: 'd-m-Y - H:i',
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i',
                    time_24hr: true,
                  }"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="d-flex justify-content-between w-100">
              <!-- <b-button 
                variant="primary"
                @click="getTotalLazy" >{{$t('buttons.search')}}</b-button> -->

              <!--<div v-if="dataTable.length > 0">
                  <b-card-text class="mb-0  mr-1">
                    Details
                  </b-card-text>
                  <b-form-checkbox
                    v-model="detailsDataChecked"
                    class="custom-control-primary"
                    name="check-button"
                    switch
                  />
                </div>-->

              <!-- <b-button 
                type="submit"
                variant="primary"
                @click="getDetails" >Details</b-button> -->
            </b-col>
          </b-row>
          <br />
          <b-overlay :show="loadindData">
            <vue-tabs class="mt-5">
              <v-tab title="Caballos">
                <div>
                  <b-table
                    class="position-relative"
                    :items="dataItems"
                    responsive
                    :fields="fields"
                  >
                  </b-table>
                </div>
              </v-tab>
              <v-tab title="Deportes">
                <div>
                  <b-table class="position-relative" responsive> </b-table>
                </div>
              </v-tab>
              <v-tab title="Deportes Online">
                <div>
                  <b-table
                    class="position-relative"
                    :items="dataDeportOnlineItems"
                    responsive
                    :fields="fieldsDeportOnline"
                  >
                  </b-table>
                </div>
              </v-tab>

              <v-tab title="Online">
                <div>
                  <b-table
                    class="position-relative"
                    :items="dataOnlineItems"
                    responsive
                    :fields="fieldsOnline"
                  >
                  </b-table>
                </div>
              </v-tab>

              <v-tab title="Resumen">
                <div>
                  <b-table
                    class="position-relative"
                    :items="dataResumenItems"
                    responsive
                    :fields="fieldsResumen"
                  >
                  </b-table>
                </div>
              </v-tab>
            </vue-tabs>
          </b-overlay>
        </b-card>
      </b-col>
      <b-modal
        id="modal-hierachy"
        hide-footer
        title="Listado de agentes"
        v-if="width<768"
      >
        <HierarchyUsers
          @selectUser="selectUserHierarchy"
          ref="HerarchiComponent"
        />
      </b-modal>
      <b-col md="3" v-else>
        <b-col class="p-0" md="12">
          <b-card no-body class="p-0 py-1">
            <HierarchyUsers
              @selectUser="selectUserHierarchy"
              ref="HerarchiComponent"
            />
          </b-card>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BTabs,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BButton,
  BAlert,
  BFormCheckbox,
  BCardText,
  BOverlay,
  BCardTitle,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTfoot,
  BTd,
  BTbody,
} from "bootstrap-vue";
import { BTable } from "bootstrap-vue";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import "vue-tree-halower/dist/halower-tree.min.css";
import { VTree, VSelectTree } from "vue-tree-halower";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import axios from "@axios";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { VueTabs, VTab } from "vue-nav-tabs";
//you can also import this in your style tag
import "vue-nav-tabs/themes/vue-tabs.css";
import HierarchyUsers from "@core/components/HierarchyUsers/HierarchyUsers.vue";

export default {
  components: {
    BTable,
    VueTabs,
    VTab,
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BCollapse,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BTableSimple,
    VSelectTree,
    BThead,
    vSelect,
    VTree,
    BTr,
    BTh,
    BForm,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    ToastificationContent,
    BAlert,
    BTfoot,
    BTd,
    BTbody,
    flatPickr,
    HierarchyUsers,
  },
  data() {
    return {
      isBusy: false,
      fields: [
        { key: "username", sortable: true, label: "Usuario" },
        { key: "netSale", sortable: true, label: "Venta neta" },
        { key: "paidOut", sortable: true, label: "Premios" },
        { key: "balance", sortable: true, label: "Saldo" },
        { key: "win", sortable: true, label: "Por pagar" },
        { key: "profit", sortable: true, label: "Saldo en caja" },
      ],
      fieldsOnline: [
        { key: "userName", sortable: true, label: "Usuario" },
        { key: "itemRecharges", sortable: true, label: "#" },
        { key: "netSale", sortable: true, label: "Recarga" },
        { key: "itemRetirement", sortable: true, label: "#" },
        { key: "paidOut", sortable: true, label: "Retiro" },
        { key: "profit", sortable: true, label: "Saldo en caja" },
      ],
      fieldsResumen: [
        { key: "username", sortable: true, label: "Usuario" },
        { key: "netSale", sortable: true, label: "Ingresos" },
        { key: "paidOut", sortable: true, label: "Egresos" },
        { key: "profit", sortable: true, label: "Saldo en caja" },
      ],
      fieldsSw3: [
        { key: "username", sortable: true, label: "Usuario" },
        { key: "netSale", sortable: true, label: "Ventas" },
        { key: "paidOut", sortable: true, label: "Por pagar" },
        { key: "pay", sortable: true, label: "Pagado" },
        { key: "profit", sortable: true, label: "Total" },
      ],
      fieldsSportsbook: [
        { key: "username", sortable: true, label: "Usuario" },
        { key: "item", sortable: true, label: "#" },
        { key: "netSale", sortable: true, label: "Venta neta" },
        { key: "paidOut", sortable: true, label: "Premios" },
        { key: "balance", sortable: true, label: "Saldo" },
        { key: "win", sortable: true, label: "Por pagar" },
      ],
      fieldsDeportOnline: [
        { key: "netSale", sortable: true, label: "Ventas" },
        { key: "paidOut", sortable: true, label: "Por pagar" },
        { key: "pay", sortable: true, label: "Pagado" },
        { key: "diff", sortable: true, label: "Total" },
      ],
      totalPlayers: null,
      dateFrom: moment().format("YYYY-MM-DD 00:00"),
      dateTo: moment().format("YYYY-MM-DD 23:59"),
      currency: "",
      currenciesOptions: [],
      initSelected: [],
      dataGamesFullDetails: [],
      dataTable: [],
      dataItems: [],
      dataOnlineItems: [],
      dataDeportOnlineItems: [],
      dataResumenItems: [],
      rangeDate: "",
      idUserSelect: "",
      currency: "",
      detailsDataChecked: false,
      loadindData: false,
      loadindDataBalancePlayer: false,


      sales: {},
      centerhourseTotal: {},
      abstractsTotal: {},

      dataTotal: {
        totals: {
          recharges: 0,
          retirement: 0,
          diff: 0,
          paneles: 0,
        },
      },
      timeOut: null,
      width: 0,
    };
  },
  computed: {
    currencySelectNavbar() {
      return this.$store.state.whitelabelCurrencyNabvar.currency;
    },
    whitelabelSelectNavbar() {
      return this.$store.state.whitelabelCurrencyNabvar.whitelabel;
    },
  },
  methods: {
    selectUserHierarchy({ id }) {
      this.idUserSelect = id;
      this.showReportCenterHorses();
      this.showOnlineReports();
      this.showResumenReport();
      this.showDeportOnline();
    },
    changeReport() {
      this.showReportCenterHorses();
      this.showOnlineReports();
      this.showResumenReport();
      this.showDeportOnline();
    },
    showReportCenterHorses() {
      try {
        this.loadingData = true;
        const id = this.idUserSelect;
        const VUE_APP_URL = process.env.VUE_APP_URL;
        const currency = this.currency;
        const whitelabel =
          this.$store.state.whitelabelCurrencyNabvar.whitelabel._id;
        const dateFrom = this.dateFrom;
        const dateTo = this.dateTo;
        const params = {
          id: id,
          token: "dbdc0d4b-576b-4168-8b99-f5ae762bb53b",
          whitelabel: whitelabel,
          currency: currency,
          whiteBrand: "http://whish-whitelabel-web2.kingconnections.net",
          dateFrom: dateFrom,
          dateAt: dateTo,
          timezone: "America/Caracas",
        };
        const result = axios
          .post(`${VUE_APP_URL}/reportDaily`, params)
          .then((r) => (this.dataItems = r.data))
          .catch((error) => console.log(error.response));
        return result;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching data list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    showOnlineReports() {
      try {
        this.loadingData = true;
        const id = this.idUserSelect;
        const VUE_APP_URL = process.env.VUE_APP_URL;
        const currency = this.currency;
        const whitelabel =
          this.$store.state.whitelabelCurrencyNabvar.whitelabel._id;
        const dateFrom = this.dateFrom;
        const dateTo = this.dateTo;
        const params = {
          id: id,
          whitelabel: whitelabel,
          currency: currency,
          dateFrom: dateFrom,
          dateAt: dateTo,
        };
        const result = axios
          .post(`${VUE_APP_URL}/onlineReports`, params)
          .then((r) => (this.dataOnlineItems = r.data.reports))
          .catch((error) => console.log(error.response));
        return result;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching data list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    selectUserHierarchy({ id }) {
      this.idUserSelect = id;
      const filter = this.filterTab
      this.daily( filter );
    },
    changeDaily() {
      const filter = this.searchReport
      this.daily( filter );
    },
    daily( filter ) {
      try {
        this.loadingData = true;
        const id = this.idUserSelect;
        const VUE_APP_URL = process.env.VUE_APP_URL;
        const currency = this.currency;
        const whitelabel =
          this.$store.state.whitelabelCurrencyNabvar.whitelabel._id;
        const dateFrom = this.dateFrom;
        const dateTo = this.dateTo;
        const params = {
          id: id,
          token: "dbdc0d4b-576b-4168-8b99-f5ae762bb53b",
          whitelabel: whitelabel,
          currency: currency,
          whiteBrand: "http://whish-whitelabel-web2.kingconnections.net",
          dateFrom: dateFrom,
          dateAt: dateTo,
          timezone: "America/Caracas",
        };
        const result = axios
          .post(`${VUE_APP_URL}/resumenReports`, params)
          .then((r) => (this.dataResumenItems = r.data))
          .catch((error) => console.log(error.response));
        return result;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching data list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    showDeportOnline() {
      try {
        this.loadingData = true;
        const id = this.idUserSelect;
        const VUE_APP_URL = process.env.VUE_APP_URL;
        const currency = this.currency;
        const whitelabel =
          this.$store.state.whitelabelCurrencyNabvar.whitelabel._id;
        const dateFrom = this.dateFrom;
        const dateTo = this.dateTo;
        const params = {
          id: id,
          whitelabel: whitelabel,
          currency: currency,
          dateStart: dateFrom,
          dateEnd: dateTo,
        };
        const result = axios
          .post(`${VUE_APP_URL}/sw3Report`, params)
          .then((r) => (this.dataDeportOnlineItems = r.data.data))
          .catch((error) => console.log(error.response));
        return result;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching data list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
  },
  created() {
    this.width = window.innerWidth;
    window.addEventListener('resize', () => {
      this.width = window.innerWidth;
    });
    this.idUserSelect = JSON.parse(localStorage.getItem("userData"))._id;
    this.currency = this.$store.state.whitelabelCurrencyNabvar.currency;
    this.showReportCenterHorses();
    this.showOnlineReports();
    this.showResumenReport();
    this.showDeportOnline();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.tree-block {
  float: left;
  width: 33%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 300px;
}
.selected {
  padding: 6px 8px;
  outline: none;
  border-radius: 6px;
  background-color: #efb810;
  border: 1px solid rgb(226, 225, 225);
  color: rgb(117, 117, 117);
}
</style>
<style>
.tab-wrap {
  -webkit-transition: 0.3s box-shadow ease;
  transition: 0.3s box-shadow ease;
  border-radius: 6px;
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  list-style: none;
  background-color: transparent;
  margin: 40px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.tab-wrap:hover {
  box-shadow: 0 12px 23px rgba(0, 0, 0, 0.23), 0 10px 10px rgba(0, 0, 0, 0.19);
}
.tab {
  display: none;
}
.tab:checked:nth-of-type(1) ~ .tab__content:nth-of-type(1) {
  opacity: 1;
  -webkit-transition: 0.5s opacity ease-in, 0.8s -webkit-transform ease;
  transition: 0.5s opacity ease-in, 0.8s -webkit-transform ease;
  transition: 0.5s opacity ease-in, 0.8s transform ease;
  transition: 0.5s opacity ease-in, 0.8s transform ease,
    0.8s -webkit-transform ease;
  position: relative;
  top: 0;
  z-index: 100;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  text-shadow: 0 0 0;
}
.tab:checked:nth-of-type(2) ~ .tab__content:nth-of-type(2) {
  opacity: 1;
  -webkit-transition: 0.5s opacity ease-in, 0.8s -webkit-transform ease;
  transition: 0.5s opacity ease-in, 0.8s -webkit-transform ease;
  transition: 0.5s opacity ease-in, 0.8s transform ease;
  transition: 0.5s opacity ease-in, 0.8s transform ease,
    0.8s -webkit-transform ease;
  position: relative;
  top: 0;
  z-index: 100;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  text-shadow: 0 0 0;
}
.tab:checked:nth-of-type(3) ~ .tab__content:nth-of-type(3) {
  opacity: 1;
  -webkit-transition: 0.5s opacity ease-in, 0.8s -webkit-transform ease;
  transition: 0.5s opacity ease-in, 0.8s -webkit-transform ease;
  transition: 0.5s opacity ease-in, 0.8s transform ease;
  transition: 0.5s opacity ease-in, 0.8s transform ease,
    0.8s -webkit-transform ease;
  position: relative;
  top: 0;
  z-index: 100;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  text-shadow: 0 0 0;
}
.tab:checked:nth-of-type(4) ~ .tab__content:nth-of-type(4) {
  opacity: 1;
  -webkit-transition: 0.5s opacity ease-in, 0.8s -webkit-transform ease;
  transition: 0.5s opacity ease-in, 0.8s -webkit-transform ease;
  transition: 0.5s opacity ease-in, 0.8s transform ease;
  transition: 0.5s opacity ease-in, 0.8s transform ease,
    0.8s -webkit-transform ease;
  position: relative;
  top: 0;
  z-index: 100;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  text-shadow: 0 0 0;
}
.tab:checked:nth-of-type(5) ~ .tab__content:nth-of-type(5) {
  opacity: 1;
  -webkit-transition: 0.5s opacity ease-in, 0.8s -webkit-transform ease;
  transition: 0.5s opacity ease-in, 0.8s -webkit-transform ease;
  transition: 0.5s opacity ease-in, 0.8s transform ease;
  transition: 0.5s opacity ease-in, 0.8s transform ease,
    0.8s -webkit-transform ease;
  position: relative;
  top: 0;
  z-index: 100;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  text-shadow: 0 0 0;
}
.tab:first-of-type:not(:last-of-type) + label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.tab:not(:first-of-type):not(:last-of-type) + label {
  border-radius: 0;
}
.tab:last-of-type:not(:first-of-type) + label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.tab:checked + label {
  background-color: #fff;
  box-shadow: 0 -1px 0 #fff inset;
  cursor: default;
}
.tab:checked + label:hover {
  box-shadow: 0 -1px 0 #fff inset;
  background-color: #fff;
}
.tab + label {
  box-shadow: 0 -1px 0 #eee inset;
  border-radius: 6px 6px 0 0;
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: #333;
  -webkit-box-flex: 3;
  -ms-flex-positive: 3;
  flex-grow: 3;
  text-align: center;
  background-color: #888787;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  -webkit-transition: 0.3s background-color ease, 0.3s box-shadow ease;
  transition: 0.3s background-color ease, 0.3s box-shadow ease;
  height: 50px;
  box-sizing: border-box;
  padding: 15px;
  font-weight: bold;
}
.tab + label:hover {
  background-color: #f9f9f9;
  box-shadow: 0 1px 0 #f4f4f4 inset;
}
.tab__content {
  padding: 10px 25px;
  background-color: transparent;
  position: absolute;
  width: 100%;
  z-index: -1;
  opacity: 0;
  left: 0;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  border-radius: 6px;
}
/* boring stuff */
.container {
  margin: 0 auto;
  display: block;
  max-width: 800px;
}
.container > *:not(.tab-wrap) {
  padding: 0 80px;
}
h1,
h2 {
  margin: 0;
  color: #444;
  text-align: center;
  font-weight: 400;
}
h2.heading {
  font-size: 2em;
  margin-bottom: 8px;
}
h2 {
  font-size: 1em;
  margin-bottom: 0;
}
h3 {
  font-weight: 400;
}
p {
  line-height: 1.6;
  margin-bottom: 20px;
}
</style>
