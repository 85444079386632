var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[(_vm.width<768)?_c('b-col',{staticClass:"mb-1",staticStyle:{"display":"flex","gap":"10px"},attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-hierachy",modifiers:{"modal-hierachy":true}}]},[_c('feather-icon',{attrs:{"icon":"GitPullRequestIcon"}})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"md":"9"}},[_c('b-card',{staticClass:"p-1",attrs:{"no-body":""}},[_c('b-card-title',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t("tabs.reportDaily")))]),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Date from","label-for":"datefrom","rules":"required"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t("labels.datefrom")))]},proxy:true}])},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                  altInput: true,
                  altFormat: 'd-m-Y - H:i',
                  enableTime: true,
                  dateFormat: 'Y-m-d H:i',
                  time_24hr: true,
                }},on:{"input":_vm.changeReport},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Date To","label-for":"dateto","rules":"required"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t("labels.dateto")))]},proxy:true}])},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                  altInput: true,
                  altFormat: 'd-m-Y - H:i',
                  enableTime: true,
                  dateFormat: 'Y-m-d H:i',
                  time_24hr: true,
                }},on:{"input":_vm.changeReport},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-between w-100",attrs:{"md":"12"}})],1),_c('br'),_c('b-overlay',{attrs:{"show":_vm.loadindData}},[_c('vue-tabs',{staticClass:"mt-5"},[_c('v-tab',{attrs:{"title":"Caballos"}},[_c('div',[_c('b-table',{staticClass:"position-relative",attrs:{"items":_vm.dataItems,"responsive":"","fields":_vm.fields}})],1)]),_c('v-tab',{attrs:{"title":"Deportes"}},[_c('div',[_c('b-table',{staticClass:"position-relative",attrs:{"responsive":""}})],1)]),_c('v-tab',{attrs:{"title":"Deportes Online"}},[_c('div',[_c('b-table',{staticClass:"position-relative",attrs:{"items":_vm.dataDeportOnlineItems,"responsive":"","fields":_vm.fieldsDeportOnline}})],1)]),_c('v-tab',{attrs:{"title":"Online"}},[_c('div',[_c('b-table',{staticClass:"position-relative",attrs:{"items":_vm.dataOnlineItems,"responsive":"","fields":_vm.fieldsOnline}})],1)]),_c('v-tab',{attrs:{"title":"Resumen"}},[_c('div',[_c('b-table',{staticClass:"position-relative",attrs:{"items":_vm.dataResumenItems,"responsive":"","fields":_vm.fieldsResumen}})],1)])],1)],1)],1)],1),(_vm.width<768)?_c('b-modal',{attrs:{"id":"modal-hierachy","hide-footer":"","title":"Listado de agentes"}},[_c('HierarchyUsers',{ref:"HerarchiComponent",on:{"selectUser":_vm.selectUserHierarchy}})],1):_c('b-col',{attrs:{"md":"3"}},[_c('b-col',{staticClass:"p-0",attrs:{"md":"12"}},[_c('b-card',{staticClass:"p-0 py-1",attrs:{"no-body":""}},[_c('HierarchyUsers',{ref:"HerarchiComponent",on:{"selectUser":_vm.selectUserHierarchy}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }